import { motion } from "framer-motion";
import { Children, FC, PropsWithChildren } from "react";
import { cardListVariants, cardVariants } from "../../utils/variants";

export const AnimatedList: FC<PropsWithChildren<{ oneColumn?: boolean }>> = ({
  children = [],
  oneColumn = true
}) => {
  return (
    <motion.section
      className="w-full"
      initial="closed"
      animate="open"
      exit="closed"
    >
      <motion.ul
        className={`flex ${oneColumn ? 'flex-col gap-6' : 'flex-row flex-wrap gap-2'} items-center justify-center w-full h-full`}
        variants={cardListVariants}
      >
        {Children.map(children, (child, index) => {
          return (
            <motion.li
              className={`${oneColumn ? 'w-full' : 'w-full lg:w-[48%]'}`}
              key={index}
              variants={cardVariants}
              whileHover={{ x: 1.02 }}
              whileTap={{ scale: 0.95 }}
            >
              {child}
            </motion.li>
          );
        })}
      </motion.ul>
    </motion.section>
  );
};
