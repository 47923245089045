import Link from "next/link";
import Image from "next/image";
import { SectionProps } from "../../types";
import { FC } from "react";
import showdown from 'showdown';

export const Section: FC<SectionProps> = ({
  link,
  picture: { url, altText },
  title,
  text = '',
  color = '#fff',
  imagePosition
}) => {
  const converter = new showdown.Converter();
  const htmlText = text ? converter.makeHtml(text) : '';

  return (
    <>
      <Link href={link} className="flex lg:hidden w-full h-full aspect-h-1 aspect-w-2">
        <article className="flex lg:hidden relative w-full h-full aspect-h-1 aspect-w-2 cursor-pointer border-white border-2 border-solid rounded-3xl overflow-hidden">
          {
            imagePosition == 'background' || imagePosition == 'right' ? <>
              <Image
                src={url}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                alt={altText}
                priority
              />
              <div className="absolute inset-0 h-full w-full bg-gray bg-opacity-50" />
              <div className="absolute inset-0 w-full flex flex-col items-center justify-center text-white text-center px-5">
                <h2 className="font-title text-xl">{title}</h2>
                {text ? <div className="max-w-none prose-p:text-md prose-p:text-white prose-em:text-white mt-2" dangerouslySetInnerHTML={{ __html: htmlText }}/> : null}
              </div>
            </>
              :
              <div className={`w-full flex flex-col items-center justify-center text-white text-center px-5`} style={{ backgroundColor: color }}>
                <h2 className="font-title text-xl">{title}</h2>
                {text ? <div className="max-w-none prose-p:text-md prose-p:text-white prose-em:text-white mt-2" dangerouslySetInnerHTML={{ __html: htmlText }}/> : null}
              </div>
          }
        </article>
      </Link>
      <Link href={link} className="hidden lg:flex w-full h-full aspect-h-1 aspect-w-2">
        <article className="hidden lg:flex relative w-full h-full aspect-h-1 aspect-w-2 cursor-pointer border-white border-2 border-solid rounded-3xl overflow-hidden">
          {
            imagePosition == 'background' ? <>
              <Image
                src={url}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                alt={altText}
                priority
              />
              <div className="absolute inset-0 h-full w-full bg-gray bg-opacity-50" />
              <div className="absolute inset-0 w-full flex flex-col items-center justify-center text-white text-center">
                <h2 className="font-title text-xl">{title}</h2>
                {text ? <div className="max-w-none prose-p:text-md prose-p:text-white prose-em:text-white mt-2" dangerouslySetInnerHTML={{ __html: htmlText }}/> : null}
              </div>
            </>
              :
              <div className="grid grid-cols-2">
                {imagePosition == 'left' ? <div className="relative w-full h-full aspect-h-1 aspect-w-1 border-white border-solid border-l-2 rounded-l-3xl overflow-hidden">
                  <Image
                    src={url}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center"
                    alt={altText}
                    priority
                  />
                  <div className="absolute inset-0 h-full w-full bg-gray bg-opacity-50" />
                </div> : null}
                <div className={`w-full flex flex-col items-center justify-center text-white text-center p-10`} style={{ backgroundColor: color }}>
                  <h2 className="font-title text-xl">{title}</h2>
                  {text ? <div className="max-w-none prose-p:text-md prose-p:text-white prose-em:text-white mt-2" dangerouslySetInnerHTML={{ __html: htmlText }}/> : null}

                </div>
                {imagePosition == 'right' ? <div className="relative w-full h-full aspect-h-1 aspect-w-1 border-white border-solid border-r-2 rounded-r-3xl overflow-hidden">
                  <Image
                    src={url}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center"
                    alt={altText}
                    priority
                  />
                  <div className="absolute inset-0 h-full w-full bg-gray bg-opacity-50" />
                </div> : null}
              </div>
          }
        </article>
      </Link>
    </>
  );
};
