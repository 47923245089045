import type { GetStaticProps, NextPage } from "next";
import { fetchAPI } from "../lib/api";
import { Layout } from "../components/layout";
import { SectionType, HomeProps } from "../types";
import { SeoComponent } from "../components/seo-component";
import { pictureMapper } from "../mapper";
import qs from "qs";
import { Section } from "../components/section";
import { AnimatedList } from "../components/animatedList";
import { ContactForm } from "../components/contact-form";
import { useIsMounted } from "../lib/use-is-mounted";

const Home: NextPage<HomeProps> = ({ data }) => {
  const sections = data?.attributes?.seccions?.data

  const isMounted = useIsMounted();

  return (
    <Layout>
      <div className="-z-10 fixed top-0 left-0 h-full w-full bg-white" />
      <div className="w-5/6 sm:w-4/5 lg:w-3/4 xl:w-4/5 2xl:w-3/5 h-full flex flex-col pb-4">
        <SeoComponent />
        <div className="w-full flex flex-col justify-start bg-white mt-12">
          <AnimatedList>
              {sections ? (sections as SectionType[]).map(
                ({ attributes: { slug, titol, imatge, color, text } }, index) => {
                  const imagePosition = index === 0 ? 'background' : index % 2 === 0 ? 'right' : 'left';
                  return (
                    <Section
                      key={index}
                      link={`/${slug}`}
                      title={titol}
                      text={text}
                      picture={pictureMapper(imatge)}
                      color={color}
                      imagePosition={imagePosition}
                    />
                  );
                }
              ) : null}
            </AnimatedList>
        </div>
        <div className="bg-beige my-8 p-10 border-beige border-2 border-solid rounded-3xl overflow-hidden">
          <h2 className="font-title text-xl text-brown text-center">Contacte</h2>
          <ContactForm />
        </div>
        {isMounted ? <figure data-behold-id="83OYLi2G8rXoYCqZshzQ"></figure> : null}
      </div>
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const query = qs.stringify(
    {
      populate: [
        "seccions.imatge",
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );
  const { data = {} } = await fetchAPI(`/api/inici?${query}`);

  return {
    props: {
      data
    },
    revalidate: 30,
  };
};

export default Home;
