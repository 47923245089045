import { SyntheticEvent, useState } from "react";
import { send } from "@emailjs/browser";

export const ContactForm = () => {
  const [message, setMessage] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleFormSubmit = async (event: SyntheticEvent) => {
    setLoading(true);

    event.preventDefault();

    const target = event.target as typeof event.target & {
      name: { value: string };
      email: { value: string };
      message: { value: string };
    };

    return send(
      process?.env?.MAILER_SERVICE || "",
      process?.env?.MAILER_TEMPLATE || "",
      {
        name: target.name.value,
        email: target.email.value,
        message: target.message.value,
      },
      process?.env?.MAILER_PUBLIC_KEY || ""
    )
      .then(() => {
        setMessage("Missatge enviat");
      })
      .catch(() => {
        setMessage(
          "El correu no s'ha pogut enviar. Si su plau, revisi els camps i torni a enviar el missatge."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <form onSubmit={handleFormSubmit}>
      <div className="flex flex-col">
        <div className="my-8 w-full">
          <input
            className="w-full bg-white bg-opacity-0 border-b-black border-b-[1px] border-dotted"
            type="text"
            id="name"
            name="name"
            placeholder="Nom"
            required
            aria-required
            minLength={5}
            maxLength={50}
          />
        </div>
        <div className="mb-8 w-full">
          <input
            className="w-full bg-white bg-opacity-0 border-b-black border-b-[1px] border-dotted"
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            pattern="(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}"
            required
            aria-required
            maxLength={50}
            title="El email debe tener un formato válido"
          />
        </div>
        <div className="mb-8 w-full">
          <textarea
            className="w-full bg-white bg-opacity-0 border-b-black border-b-[1px] border-dotted"
            id="message"
            name="message"
            placeholder="Com et puc ajudar?"
            rows={3}
            required
            aria-required
          />
        </div>
        <div className="mb-5 w-full flex items-center justify-center">
          <button
            className="flex items-center justify-center p-2 bg-brown border-2 border-solid border-brown rounded-full text-white"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <div>
                <svg
                  stroke="white"
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-brown"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="white"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="white"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            ) : null}
            <p>Enviar</p>
          </button>
        </div>
        {message ? (
          <div className="max-w-fit mt-8 self-center">
            <p className="bg-green py-3 px-6 text-white text-center">
              {message}
            </p>
          </div>
        ) : null}
      </div>
    </form>
  );
};
