import { ImageType , MappedPdfType, PdfType, PictureType } from "../types";

export const pictureMapper = (
  picture: PictureType | undefined
): ImageType => {
  return {
    url: `${picture?.data?.attributes?.hash}${picture?.data?.attributes?.ext}`,
    altText: picture?.data?.attributes?.alternativeText || "",
  };
};

export const pdfMapper = (
  pdf: PdfType | undefined
): MappedPdfType => {
  return {
    url: pdf?.data?.attributes?.url || '',
    name: pdf?.data?.attributes?.name || '',
  };
};
